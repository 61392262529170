import ClientAboutPage from 'components/PageLevelComponents/ClientAboutPage';
import { ANONYMOUS_TOKEN_NAME, LOCALE_DEFAULT } from 'config/config';
import { AboutPageController } from 'server/controller/page/about/aboutPageController';
import { IAboutPageResource } from 'server/resource/page/aboutPageResource';
import type { IGlobalMetaData } from 'types/IGlobalMetaData';
import { Layout } from '@/components/Layout/Layout';
import { GetServerSideProps } from 'next';

export default function Page(props: IAboutPageResource) {
  const {
    metaData,
    data,
    breadcrumbsCMSData,
    difficultyCopy,
    searchOverlayData,
    searchItemLabels,
    recipeCopy,
    footerNavData,
    footerImage,
    usercentricsIds,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    locale,
    pathTranslations,
    userToken,
  } = props;

  return (
    <Layout
      themeName={data?.genericPages.at(0)?.theme || ''}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      userToken={userToken}
    >
      <ClientAboutPage
        globalMetadata={metaData as IGlobalMetaData}
        data={data}
        breadcrumbsCMSData={breadcrumbsCMSData}
        difficultyCopy={difficultyCopy as Record<string, string>}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
        recipeCopy={recipeCopy}
      />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const anonymousToken = req.cookies[ANONYMOUS_TOKEN_NAME];

  return AboutPageController.getCachedAboutPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: Array.isArray(params?.slug) ? params.slug : [],
    userToken: anonymousToken ?? '',
  });
};
